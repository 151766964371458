import React, { useState } from "react";

import { useStore } from "@state/store";
import AccountTemplate from "@components/account/accountTemplate";
import AccountPreferences from "@shopify/accountPreferences";

export default function Orders() {
  const { shopify } = useStore();

  return (
    <AccountTemplate shopify={shopify} handle="preferences">
      <AccountPreferences shopify={shopify} />
    </AccountTemplate>
  );
}
